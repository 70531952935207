.slick-prev::before, .slick-next::before {
    color: #000;
} 


.slider-area {

width: 100%;
max-width: 100px;

}

/* Change arrow color to white on screens with a max-width of 768px (adjust as needed) */
@media (max-width: 768px) {
    .slick-prev::before,
    .slick-next::before {
        color: #fff; /* White color for mobile screens */
    }
}
.slick-prev {
    left: 16px;
}

.slick-next {
    right: 16px;
}
.slick-slide {
 
  
    text-align:center;
    margin-right:0;
    margin-left:0;
}
