:root {
    --main-color: #1F5ABC;
    --main-color2: #EE0020;
    --heading-color: #101A29;
    --paragraph-color: #737588;
    --body-font: 'Roboto', sans-serif;
    --heading-font: 'Roboto', sans-serif;
    --body-font-size: 16px;
    --line-height30: 1.7;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow-x: hidden;
    font-family: var(--body-font);
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    -moz-osx-font-smoothing: grayscale;
    /* Firefox */
    -webkit-font-smoothing: antialiased;
    /* WebKit  */
}


@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* Add font-display property to control font loading behavior */
/* The 'swap' value ensures text remains visible while the font is being fetched */
/* 'fallback' can be used as a more conservative option for slower connections */
/* 'optional' is also available if you prefer to load the font only if it's used */
@font-face {
  font-family: 'Roboto';
  font-display: optional;
}



.arrow-icon {
    background-color: var(--main-color);
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  
body {
    margin: 0;
    color: var(--paragraph-color);
    overflow-x: hidden;
    font-family: var(--body-font);
    line-height: var(--line-height30);
    font-size: var(--body-font-size);
    background: #fff;
    font-weight: 500;
    padding-right: 0 !important;
}

h1 {
    font-size: 25px;
    line-height: 1.2333333333333333;
}

h2 {
    font-size: 27px;
    line-height: 1.2380952380952381;
}

h3 {
    font-size: 20px;
    line-height: 1.3833333333333333;
}

h4 {
    font-size: 15px;
    line-height: 1.3380952380952381;
}
h5 {
    font-size: 20px;
    line-height: 1.3380952380952381;
}
h6 {
    font-size: 16px;
    line-height: 1.2380952380952381;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--heading-color);
    font-weight: 600;
    font-family: var(--heading-font);
}

p {
    color: var(--paragraph-color);
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    margin-bottom: 10px;
}
/* Media query for mobile screens (up to 767px wide) */
@media (max-width: 767px) {
  h2 {
    font-size: 26px; /* Font size for mobile screens */
  }
}
@media screen and (min-width: 1366px) {

  p {
    font-size: 15px; /* Font size for 1366px screen width and larger */
  }

}

a {
    color: inherit;
    text-decoration: none;
    transition: 0.4s;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: inherit;
}
a:hover {
    color: var(--main-color);
}
pre {
    word-break: break-word;
}

a i {
    padding: 0 2px;
}

img {
    max-width: 100%;
}

button:hover,
button:active,
button:focus {
    outline: 0;
}

/*input and button type focus outline disable*/

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ddd;
}

.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid{
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.no-gutter>[class^="col-"]{
  padding-left: 0;
  padding-right: 0;
}
.no-gutter[class^="col-"]{
    padding-left: 0;
    padding-right: 0;
  }

.h-100vh {
    height: 100vh;
}

code {
    color: #faa603;
}

.check-list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        display: block;
        padding-left: 20px;
        position: relative;
        z-index: 0;
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            font-family: 'fontawesome';
            content: "\f105";
            color: var(--main-color);
        }
    }
}

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
    clear: both;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
    float: left;
    width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
    float: right;
    text-align: right;
    width: 50%;
}

.comment-navigation .nav-previous>a,
.posts-navigation .nav-previous>a,
.post-navigation .nav-previous>a,
.comment-navigation .nav-next>a,
.posts-navigation .nav-next>a,
.post-navigation .nav-next>a {
    transition: .3s ease-in;
}

.comment-navigation .nav-previous:hover>a,
.posts-navigation .nav-previous:hover>a,
.post-navigation .nav-previous:hover>a,
.comment-navigation .nav-next:hover>a,
.posts-navigation .nav-next:hover>a,
.post-navigation .nav-next:hover>a {
    color: var(--main-color);
}

.comment-list li {
    list-style: none;
}

.h-100vh {
    height: 100vh;
}

.position-relative {
    position: relative;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/


/* Text meant only for screen readers. */

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
    /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    clip-path: none;
    color: #21759b;
    display: block;
    font-size: 15px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */
}


/* Do not show the outline on the skip link target. */

#content[tabindex="-1"]:focus {
    outline: 0;
}


.fa-icons {
    color: #fff;
    font-size: 43px;

}


/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/

.alignleft {
    float: left;
    clear: both;
    margin-right: 20px;
}

.alignright {
    float: right;
    clear: both;
    margin-left: 20px;
}

.aligncenter {
    clear: both;
    display: block;
    margin: 0 auto 1.75em;
}

.alignfull {
    margin: 1.5em 0;
    max-width: 100%;
}

.alignwide {
    max-width: 1100px;
}


/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/

.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
    content: "";
    display: table;
    table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
    clear: both;
}


/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/

.sticky {
    display: block;
}

.updated:not(.published) {
    display: none;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}


/* Make sure embeds and iframes fit their containers. */

embed,
iframe,
object {
    max-width: 100%;
}


/* Make sure logo link wraps around logo image. */

.custom-logo-link {
    display: inline-block;
}


/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/

.wp-caption {
    margin-bottom: 1.5em;
    max-width: 100%;
    clear: both;
}

.wp-caption img[class*="wp-image-"] {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption .wp-caption-text {
    margin: 0.8075em 0;
}

.wp-caption-text {
    text-align: center;
}

/*----------------------------------------
    # Unit test
------------------------------------------*/

.wp-link-pages a {
    margin: 0 5px;
    transition: .3s ease-in;
}

.wp-link-pages {
    margin-bottom: 30px;
    margin-top: 25px;
}

.wp-link-pages span,
.wp-link-pages a {
    border: 1px solid #e2e2e2;
    padding: 5px 15px;
    display: inline-block;
}

.wp-link-pages .current,
.wp-link-pages a:hover {
    background-color: var(--main-color);
    color: #fff;
    border-color: var(--main-color);
}

.wp-link-pages span:first-child {
    margin-right: 5px;
}

dl,
ol,
ul {
    padding-left: 17px;
}

.post-password-form input {
    display: block;
    border: 1px solid #e2e2e2;
    height: 50px;
    border-radius: 3px;
    padding: 0 20px;
}

.post-password-form label {
    font-weight: 600;
    color: #333;
}

.post-password-form input[type=submit] {
    width: 100px;
    height: 50px;
    background-color: var(--main-color);
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
    transition: .3s ease-in;
}

.post-password-form input[type=submit]:hover {
    background-color: #121A2F;
}

.footer-widget .table td,
.footer-widget .table th {
    padding: 0.50rem !important;
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.custom-gutters-10 > .col,
.custom-gutters-10 > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
}

@media all and (min-width: 1200px) {
    .no-lg-gutters>.col, 
    .no-lg-gutters>[class*=col-] {
        padding-right: 0;
        padding-left: 0;
    }
}

.custom-icon {
    /* Add your desired styles for all icons here */
    color: var(--main-color); /* Example color */
    font-size: 2em; /* Example font size */
    margin: 10px; /* Example margin */
    /* Add any other styles you want for the icons */
  }
  .notification {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }
  
  .notification-success {
    color: green;
  }
  
  .notification-error {
    color: red;
  }
  
  .nav-link.active {
    color: var(--main-color)!important; /* New color for active link */
    /* ... other styling ... */
  }



.pricing-title-wrapper {
    display: inline-block;
    vertical-align: top;
    padding: 8px 22px 7px;
    text-align: center;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    color:var(--main-color);
    margin-bottom: 16px;
}


.pricing-icon {

    padding-right: 10px;
}

.modal-header .btn-close{
	padding: 21px !important;
	margin-top: -33px !important;


}

.ty-submit {
    /* Apply your desired text styles here */
    font-size: 16px;
    border: none;
    padding: 10px 20px !important;
    cursor: pointer;
  }


  /*Terms and Conditions*/
 
  .terms-content {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 120px;
  } 
  @media (max-width: 575.98px) {
    .terms-content {
      padding: 20px; /* Adjust this value for mobile screens */
    }
  }
  

 /* New styles for the gallery overlay */
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }
  
  .modal-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .modal-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  
  .modal-nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 1;
  }
  
  .modal-nav-button.left {
    left: 20px;
  }
  
  .modal-nav-button.right {
    right: 20px;
  }

  .image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
  }
  
  .image-gallery img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
  
  /* PestsGallery.css */



//CTA AREA
.cte-icon {
    border-color: #e4e9ff;
    background-color: #e4e9ff;
    font-size: 40px;
    line-height: 1em;
    padding: 20px;
    width: 1em;
    height: 1em;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    text-align: center;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    display: inline-block;
    vertical-align: top;
    color: var(--main-color);
}

.fa-lightbulb{
    color: var(--main-color) !important; // Use your color variable here
  }

.cte-divider::before {
    position: relative;
    width: 2px;
    height: 100%;
    margin: 0;
    background-color: var(--main-color);
}

.int-1 {
    padding-top: 6px ;

}

@media screen and (max-width: 768px) {
    /* Adjust the font size for h3 on smaller screens */
    .int-1  {
      font-size: 20px;
      padding-left: 15px;
    }
  }


  @media screen and (max-width: 768px) {
    /* Adjust the font size for h3 on smaller screens */
    .cte-icon  {
      font-size: 18px;
    }
  }

  .slider-transition {
    transition: transform 0.5s ease-in-out;
  }

  /*Acordion*/
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: #e9effd;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}


.img-flow{

    overflow: hidden;
}
.single-service-img img {
    transform: scale(1.00);
    transition: 0.9s;
    
}
.single-service-img img:hover {
    transform: scale(1.05);
    transition: 0.9s;
}
.card:hover {

    -webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,.1);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.1);
    transition: 0.9s;
}
.card {
    transition: 0.9s;
}

/* In your CSS file (e.g., App.css) */
.contact-galaxy {
    width: 100%;
    height: 450px; /* Adjust the height as needed */
  }



  /* AnnouncementNotification.css */
.announcement-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
    z-index: 9999; /* Ensure it's above other elements */
    animation: slide-down 0.5s ease-in-out; /* Add a sliding animation */
  }
  
  @keyframes slide-down {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  

  .notification-banner {
    position: absolute;
    width: 100%;
    top: -60px; /* Initial position, above the navbar */
    transition: top 0.5s ease-in-out;
    z-index: 9999; /* Ensure it's above other content */
  }
  
  .notification-banner.slide-down {
    top: 0; /* Slides down to the top of the viewport */
  }
  


/* Add this to your CSS file */
.cookie-notice{
    position: fixed !important; /* Use !important to ensure it takes precedence */
    bottom: 25px;
    left: 0;
    width: 100%;
    background: #fafafa00 !important;
    color: #ffffff !important;
    padding: 5px 0;
    text-align: center;
    z-index: 9999; /* Ensure it's on top of other content */
   

  }

  .cookie-cont{

    background-color: var(--main-color);
    padding: 10px;
    border: 0;
    border-radius: 25px;
    color: #fff;
    box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
  }
  
  .btn-border-cust {
    color: #fff;
    border: 2px solid #ffffff !important;
    line-height: 52px;
}

#rcc-confirm-button{

    display: none !important;
}

.scroll-to-top {
    background-color: white;
    right: 32px !important;
    bottom: 90px;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    border: none;
}
@media (max-width: 768px) {
    .scroll-to-top {
      display: none;
    }
  }

.truncate-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis (...) for overflowed text */
  }
  
.eb-toc-title {
    text-align: left !important;
    cursor: default !important;
    color: #fff !important;
    background-color: var(--main-color) !important;
    padding-left: 10px !important;
    font-size: 22px !important;
    font-family: var(--heading-font) !important;
  }
  
  
.eb-toc-wrapper {
    background-color: #f8f9fa;
    text-align: left;
  }
.eb-toc__list-wrap ul {
    display: inline-block;
    margin: 0 0 1.5em 1.3em;
    padding: 0 0 0 1.3em;
    text-align: left;
  }
.wp-block-table-of-contents-block-table-of-contents-block{
  
    padding-top: 8px;
    padding-bottom: 14px;
  }
  
.eb-toc__list-wrap{
    padding-top: 19px;
  }

.service-colortag{
    color: var(--main-color);
}

.gallery-title {
    background-color: #1f5abc;
    padding: 0.5em;
    color: #fff;
    text-align: center;

}

.gallery-title:hover a:hover {
 
    color: #fff !important;
    text-align: center;

}
@media (max-width: 768px) {
    p {
      text-align: justify; /* Justify align on mobile screens */
    
      hyphens: none;
      word-spacing: 0.05em;
    }
  }
  

// comments-section
/* Style for the comments section container */
.comments-section {
    margin: 20px;
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  /* Style for the "Comments" heading */
  .comments-section h4 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  /* Style for the list of comments */
  .comment-list {
    list-style: none;
    padding: 0;
  }
  
  /* Style for individual comments */
  .comment {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }
  
  /* Style for the comment author */
  .comment-author {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Style for the comment content */
  .comment-content {
    line-height: 1.4;
    font-size: 16px;
  }
  
  /* Style for the user icon */
  .comment-author svg {
    margin-right: 5px;
    vertical-align: middle;
  }
  

//   slider area
.slider-area img{

width: 40% !important;
}